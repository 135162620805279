import styled from 'styled-components';
import { complementaryColor, primaryColor, tertiaryColor } from '@styles/utils';

export const PagesFAQWrapper = styled.div`
  margin-top: 64px;
  background-color: ${complementaryColor('white')};
`;

export const PagesFAQContainer = styled.div`
  margin: 0 auto;
  padding: 64px 0 56px 0;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const PagesFAQContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
`;

export const PagesFAQItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PagesFAQItemHeader = styled.div<{ $show: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.$show ? '#0b654c' : tertiaryColor(100))};
  cursor: pointer;
  color: ${props => (props.$show ? complementaryColor('white') : tertiaryColor(700))};
`;

export const PagesFAQItemArrow = styled.svg<{ $show: boolean }>`
  fill: ${props => (props.$show ? complementaryColor('white') : primaryColor(500))};
  transform: ${props => (props.$show ? 'rotate(180deg)' : 'none')};
  transition: all 0.4s linear;
`;

export const PagesFAQItemContent = styled.div<{ $show: boolean }>`
  display: ${props => (props.$show ? 'inherit' : 'none')};
  padding: 20px;
  background-color: ${tertiaryColor(100)};
`;
