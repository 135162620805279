import React, { FC } from 'react';
import * as Styled from './PagesDiagnostics.styles';
import * as prismicH from '@prismicio/helpers';
import * as A from 'fp-ts/Array';
import * as DiagnosticsService from '@modules/diagnostics/service';
import { Diagnostic } from '@modules/diagnostics/model';
import Title from '@shared/components/title/Title';
import { PagesPrismicSliceDiagnostics } from '@modules/pages/model';
import { RichText } from '@core/prismic';
import { getCurrentLocale, getInternationalizeStringValue } from '@shared/modules/translation/utils';
import { useRouter } from 'next/router';
import { pipe } from 'fp-ts/function';
import { useSendTask } from '@core/http/hooks';
import { filterEmptyStringToOption } from '@shared/utils/string';
import * as O from 'fp-ts/Option';

interface PagesDiagnosticsProps {
  item: PagesPrismicSliceDiagnostics;
  diagnostics: Array<Diagnostic>;
}

const PagesDiagnostics: FC<PagesDiagnosticsProps> = ({ item, diagnostics }) => {
  const { primary, items } = item;

  const [, tracking] = useSendTask(DiagnosticsService.trackingDiagnostics);

  const it = pipe(
    items,
    A.filterMap(item => filterEmptyStringToOption(item.diagnosticId)),
    A.filterMap(item => {
      const value = parseInt(item);

      return isNaN(value) ? O.none : O.some(value);
    }),
    A.filterMap(it =>
      pipe(
        diagnostics,
        A.findFirst(diag => diag.number === it),
      ),
    ),
  );

  const router = useRouter();

  const handleClick = (diagnosticNumber: number) => () => {
    return tracking(diagnosticNumber);
  };

  return (
    <Styled.PagesDiagnosticsContainer>
      <Title title={prismicH.asText(primary.title)} />

      <Styled.PagesDiagnosticsDescription>
        <RichText content={primary.description} />
      </Styled.PagesDiagnosticsDescription>

      <Styled.PagesDiagnosticsContent>
        {it.map((diagnostic, index) => (
          <Styled.PagesDiagnosticsItem
            key={index}
            to="step"
            params={{ diagnostic_number: diagnostic.number, step_id: diagnostic.initialStepId }}
            onClick={handleClick(diagnostic.number)}>
            <Styled.PagesDiagnosticsItemLabel>
              {getInternationalizeStringValue(diagnostic.name, getCurrentLocale(router))}
            </Styled.PagesDiagnosticsItemLabel>

            <Styled.PagesDiagnosticsItemImage src={diagnostic.image} height={159} />
          </Styled.PagesDiagnosticsItem>
        ))}
      </Styled.PagesDiagnosticsContent>
    </Styled.PagesDiagnosticsContainer>
  );
};

export default PagesDiagnostics;
