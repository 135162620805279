import React, { FC } from 'react';
import * as Styled from './Modal.styles';
import ReactModal from 'react-modal';
import { useHideScrollBar } from '@shared/hooks/scroll';

interface ModalProps extends ReactModal.Props {
  width?: number;
  closeButton?: boolean;
}

const Modal: FC<ModalProps> = ({ isOpen, width, closeButton, onRequestClose, children, style, ...rest }) => {
  useHideScrollBar(isOpen);

  const modalStyle: ReactModal.Styles = {
    ...style,
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0',
      maxHeight: '100%',
      zIndex: 10000,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      ...style?.overlay,
    },
    content: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'auto',
      right: 'auto',
      left: 'auto',
      bottom: 'auto',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      padding: closeButton ? '80px 40px 40px' : '40px',
      border: 'none',
      background: '#2e3234',
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.07)',
      borderRadius: '2px',
      overflowY: 'auto',
      ...style?.content,
    },
  };

  return (
    <ReactModal isOpen={isOpen} {...rest} style={modalStyle} onRequestClose={onRequestClose}>
      {closeButton && <Styled.CloseModalButton onClick={onRequestClose} />}
      {children}
    </ReactModal>
  );
};

export default Modal;
