import React from 'react';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { getPageInitialProps, HttpError, HttpResult } from '@core/http';
import { getPrismicDocumentByUid, PrismicDocument } from '@core/prismic';
import { renderHttpResult } from '@shared/utils/render';
import { NextPageWithLayout } from 'next';
import { pipe } from 'fp-ts/function';
import { getStringQuery, queryToHttpTask } from '@shared/utils/queries';
import Pages from '@modules/pages/Pages';
import { PagesPrismicContent } from '@modules/pages/model';

import * as DiagnosticsService from '@modules/diagnostics/service';
import { Diagnostic } from '@modules/diagnostics/model';
import { extractDiagnosticsIds } from '@modules/pages/utils';

interface PagesPageInitialProps {
  document: PrismicDocument<PagesPrismicContent>;
  diagnostics: Array<Diagnostic>;
}

const PagesPage: NextPageWithLayout<HttpResult<PagesPageInitialProps>> = res =>
  renderHttpResult(res, ({ document, diagnostics }) => <Pages document={document} diagnostics={diagnostics} />);

PagesPage.getLayoutProps = () => ({ headerLight: true });

PagesPage.getInitialProps = getPageInitialProps(ctx => {
  const getDiagnostics = (document: PrismicDocument<PagesPrismicContent>) => {
    return pipe(
      extractDiagnosticsIds(document),
      O.fold(
        () => TE.right<HttpError, Array<Diagnostic>>([]),
        ids => DiagnosticsService.getDiagnosticsByNumbers(ids, ctx),
      ),
    );
  };

  return pipe(
    TE.Do,
    TE.bind('uid', () => queryToHttpTask(getStringQuery(ctx.query, 'uid'))),
    TE.bind('document', ({ uid }) => getPrismicDocumentByUid<PagesPrismicContent>('pages', uid)(ctx)),
    TE.bind('diagnostics', ({ document }) => getDiagnostics(document)),
  );
});

export default PagesPage;
