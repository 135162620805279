import React, { FC } from 'react';
import * as prismicT from '@prismicio/types';
import Lightbox from 'react-image-lightbox';
import { isFilled } from '@prismicio/helpers';
import { buildImgixSrc } from '@shared/utils/images';
import 'react-image-lightbox/style.css';

interface ImagesDetailProps {
  images: Array<prismicT.ImageField>;
  onClose: () => void;
  index: number;
  onMovePrevRequest: () => void;
  onMoveNextRequest: () => void;
}

const ImagesDetail: FC<ImagesDetailProps> = ({ images, onClose, index, onMovePrevRequest, onMoveNextRequest }) => {
  const params = {
    auto: 'compress,format',
    w: 1500,
    q: 60,
  };

  const image = isFilled.image(images[index]) ? images[index].url : undefined;
  const nextImage = isFilled.image(images[index + 1]) ? images[index + 1].url : undefined;
  const prevImage = isFilled.image(images[index - 1]) ? images[index - 1].url : undefined;

  return image ? (
    <Lightbox
      mainSrc={buildImgixSrc(image, params)}
      nextSrc={nextImage ?? undefined}
      prevSrc={prevImage ?? undefined}
      onCloseRequest={onClose}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  ) : null;
};

export default ImagesDetail;
