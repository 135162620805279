import styled from 'styled-components';
import { RichText } from '@core/prismic';
import { fontSize, fontWeight, mediaMax, primaryColor, tertiaryColor } from '@styles/utils';

export const PagesRichTextContainer = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 40px 16px;
  }

  @media screen and (${mediaMax.tablet}) {
    padding-top: 20px;
  }
`;

export const PagesRichTextDescription = styled(RichText)`
  p {
    padding-bottom: 10px;
    font-family: inherit;
    ${fontSize.standard};
    font-weight: ${fontWeight.normal};
  }

  h2 {
    padding-bottom: 48px;
  }

  h3 {
    position: relative;
    padding-bottom: 18px;
    color: ${tertiaryColor(700)};

    &:before {
      content: '';
      position: absolute;
      left: -43px;
      top: 12px;
      width: 30px;
      height: 4px;
      background-color: ${primaryColor(500)};

      @media screen and (max-width: 1230px) {
        display: none;
      }
    }
  }

  li {
    padding-bottom: 10px;
  }

  ol {
    padding-left: 0;

    li {
      padding-left: 43px;
      position: relative;
      list-style: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 25px;
        color: ${tertiaryColor(700)};
        background-color: ${primaryColor(100)};
        transform: rotate(-10deg) translateX(-5px);
      }

      &:after {
        position: absolute;
        left: 5px;
        top: 0;
        transform: translateY(5px) translateX(1px);
        content: counter(list-item) '';
        ${fontSize.extraSmall};
        font-weight: ${fontWeight.bold};
      }
    }
  }

  ol,
  ul {
    padding-bottom: 32px;
  }

  p,
  h2,
  h3,
  li,
  ol,
  ul {
    &:last-child {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 1230px) {
    h2 {
      padding-bottom: 16px;
    }

    h3 {
      padding-bottom: 12px;
    }
  }
`;
