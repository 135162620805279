import styled from 'styled-components';
import { complementaryColor, fontSize, fontWeight, tertiaryColor } from '@styles/utils';

export const PagesDocumentsContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 40px 16px;
  }
`;

export const PagesDocumentsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media screen and (max-width: 1230px) {
    grid-template-columns: 1fr;
  }
`;

export const PagesDocumentsItem = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 60px;
  background-color: ${complementaryColor('white')};
`;

export const PagesDocumentsText = styled.div`
  padding: 5px 15px 5px 5px;
`;

export const PagesDocumentsTitle = styled.p`
  padding-bottom: 8px;
  ${fontSize.label};
  font-weight: ${fontWeight.standard};
  color: ${tertiaryColor(700)};
  line-height: 1.63;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: 1230px) {
    padding-bottom: 0;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
  }
`;

export const PagesDocumentsDescription = styled.p`
  ${fontSize.label};
  font-weight: ${fontWeight.normal};
  color: ${tertiaryColor(700)};
  line-height: 1.81;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PagesDocumentsImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #cbcbcb;
`;
