import { PrismicDocument } from '@core/prismic';
import { PagesPrismicContent, PagesPrismicContentSlices } from '@modules/pages/model';

import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import { SearchableDocument, SearchableDocumentContent, SearchRole } from '@modules/search/model';
import { getInternationalizeStringValue, prismicLocaleToLocale } from '@shared/modules/translation/utils';
import * as R from 'fp-ts/Record';
import * as prismicH from '@prismicio/helpers';
import { canAccessSearchContent } from '@modules/search/utils';
import { v4 as uuid } from 'uuid';
import { Diagnostic } from '@modules/diagnostics/model';
import { Locale } from '@shared/modules/translation/model';
import { filterEmptyStringToOption } from '@shared/utils/string';

export function extractDiagnosticsIds(
  document: PrismicDocument<PagesPrismicContent>,
): O.Option<NEA.NonEmptyArray<number>> {
  return pipe(
    document.data.slices,
    A.filterMap(slice => (slice.slice_type === 'diagnostics' ? O.some(slice) : O.none)),
    A.chain(slice => slice.items),
    A.filterMap(item => filterEmptyStringToOption(item.diagnosticId)),
    A.filterMap(item => {
      const value = parseInt(item);

      return isNaN(value) ? O.none : O.some(value);
    }),
    A.uniq(N.Eq),
    NEA.fromArray,
  );
}

function mapPagesSlice(
  slice: PagesPrismicContentSlices,
  role: SearchRole,
  locale: Locale,
  diagnostics: Array<Diagnostic>,
): SearchableDocumentContent {
  switch (slice.slice_type) {
    case 'rich_text':
      return prismicH.asText(slice.primary.text);

    case 'photos':
      return prismicH.asText(slice.primary.title);

    case 'videos': {
      return pipe(
        slice.items,
        A.filter(item => canAccessSearchContent(item.displayBlock, role)),
        A.map(item => item.videoTitle),
        NEA.fromArray,
        O.map(items => ({
          title: prismicH.asText(slice.primary.title),
          items,
        })),
        O.toNullable,
      );
    }

    case 'documents': {
      return pipe(
        slice.items,
        A.filter(item => canAccessSearchContent(item.displayBlock, role)),
        A.map(item => ({
          title: item.title,
          description: item.description,
        })),
        NEA.fromArray,
        O.map(items => ({
          title: prismicH.asText(slice.primary.title),
          items,
        })),
        O.toNullable,
      );
    }

    case 'navigation':
      return {
        title: prismicH.asText(slice.primary.title),
        items: pipe(
          slice.items,
          A.map(item => ({
            title: item.title,
            description: item.description,
          })),
        ),
      };

    case 'faq':
      return {
        title: prismicH.asText(slice.primary.title),
        items: pipe(
          slice.items,
          A.map(item => ({
            title: prismicH.asText(item.title),
            text: prismicH.asText(item.text),
          })),
        ),
      };

    case 'diagnostics':
      return {
        title: prismicH.asText(slice.primary.title),
        description: prismicH.asText(slice.primary.description),
        items: pipe(
          slice.items,
          A.filterMap(item => filterEmptyStringToOption(item.diagnosticId)),
          A.filterMap(item => {
            const value = parseInt(item);

            return isNaN(value) ? O.none : O.some(value);
          }),
          A.filterMap(item =>
            pipe(
              diagnostics,
              A.findFirst(diagnostic => diagnostic.number === item),
              O.map(diagnostic => getInternationalizeStringValue(diagnostic.name, locale)),
            ),
          ),
        ),
      };

    default:
      return null;
  }
}

export function mapPrismicPageToSearchableItem(
  document: PrismicDocument<PagesPrismicContent>,
  diagnostics: Array<Diagnostic>,
): Array<SearchableDocument> {
  const { data } = document;

  const locale = prismicLocaleToLocale(document.lang);

  return pipe(
    SearchRole,
    R.toArray,
    A.map(([, role]) => ({
      objectID: uuid(),
      id: document.id,
      type: 'pages',
      uid: document.uid,
      role,
      lang: locale,
      data: {
        title: prismicH.asText(data.title),
        slices: pipe(
          data.slices,
          A.filterMap(slice => O.fromNullable(mapPagesSlice(slice, role, locale, diagnostics))),
        ),
      },
    })),
  );
}
