import React, { FC, useState } from 'react';
import * as Styled from './Image.styles';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Resource } from '@shared/modules/resources/model';
import generateSizes, { SizesParams } from 'html-sizes';
import { SrcSetOptions } from '@imgix/js-core';
import { renderOptional } from '@shared/utils/render';
import { buildImgixSrcAndSrcSets } from '@shared/utils/images';

const DEFAULT_IMGIX_PARAMS = {
  auto: 'compress,format',
  q: 45,
};

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'sizes' | 'placeholder' | 'resource'> {
  tag?: 'img' | 'source';
  src?: Pick<Resource, 'url'> | string | null;
  sizes?: SizesParams;
  imgixParams?: Record<string, any>;
  lazy?: boolean;
  srcSetOptions?: SrcSetOptions;
  className?: string;
}

const Image: FC<ImageProps> = ({
  tag = 'img',
  src,
  sizes: receivedSizes,
  width,
  height,
  imgixParams,
  lazy,
  srcSetOptions: receivedSrcSetOptions = {},
  className: receivedClassName,
  ...props
}) => {
  const [sizes] = useState(() => (receivedSizes ? generateSizes(receivedSizes, { maxDPR: 2 }) : undefined));

  const resourceUrl = pipe(
    O.fromNullable(src),
    O.chainNullableK(resource => (typeof resource === 'string' ? resource : resource.url)),
  );

  return renderOptional(resourceUrl, url => {
    const srcSetOptions: SrcSetOptions = {
      ...receivedSrcSetOptions,
      minWidth: receivedSrcSetOptions.minWidth ?? 300,
      maxWidth: receivedSrcSetOptions.maxWidth ?? 2000,
      devicePixelRatios: [1, 2],
    };

    const [src, srcSet] = buildImgixSrcAndSrcSets(
      url,
      { ...DEFAULT_IMGIX_PARAMS, ...imgixParams, w: width, h: height },
      srcSetOptions,
    );

    const className = lazy ? `${receivedClassName ? `${receivedClassName} ` : ''}` : receivedClassName;

    const attributes = lazy
      ? {
          'data-sizes': sizes,
          'data-src': src,
          'data-srcset': srcSet,
        }
      : {
          sizes: sizes,
          src: src,
          srcSet: srcSet,
        };

    return (
      <Styled.ImageStyle as={tag} width={width} height={height} {...attributes} className={className} {...props} />
    );
  });
};

export default Image;
