import { useState, FocusEvent, ChangeEvent } from 'react';
import { css, DefaultTheme, ThemeProps } from 'styled-components';
import { complementaryColor, fontSize, fontWeight, tertiaryColor } from '../../utils';

export const useFormElementEvent = <T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
  value?: string | string[] | readonly string[] | number,
  onFocus?: (e: FocusEvent<T>) => void,
  onBlur?: (e: FocusEvent<T>) => void,
  onChange?: (e: ChangeEvent<T>) => void,
) => {
  const [active, setActive] = useState<boolean>(!!value && typeof value === 'string' && value.length > 0);
  const [focused, setFocused] = useState<boolean>(false);

  const handleFocus = (e: FocusEvent<T>) => {
    setActive(true);
    setFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<T>) => {
    setActive(e.target.value.length !== 0);
    setFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e: ChangeEvent<T>) => {
    setActive(e.target.value.length !== 0);
    setFocused(false);

    if (onChange) {
      onChange(e);
    }
  };

  return {
    active,
    focused,
    handleFocus,
    handleBlur,
    handleChange,
  };
};

export function getColorFromProps(error?: boolean, inverted?: boolean): (props: ThemeProps<DefaultTheme>) => string {
  return props => (error ? complementaryColor('lightPink')(props) : tertiaryColor(900)(props));
}

export function getSharedFormElementStyle(
  active: boolean,
  focused: boolean,
  inverted?: boolean,
  error?: boolean,
  textArea?: boolean,
) {
  return css`
    position: relative;
    padding: 25px 0 5px;
    ${fontSize.label};
    color: ${getColorFromProps(error, inverted)};
    width: ${textArea ? '100%' : 'auto'};

    label {
      position: absolute;
      left: 0;
      top: 0;
      ${fontSize.label};
      font-weight: ${fontWeight.standard};
      opacity: 1;
      z-index: 0;
    }
  `;
}
