import React, { FC } from 'react';
import * as Styled from './PagesVideos.styles';
import * as prismicH from '@prismicio/helpers';
import { PagesPrismicSliceVideos } from '@modules/pages/model';
import Title from '@shared/components/title/Title';
import GalleryVideos from '@modules/pages/components/videos/GalleryVideos';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { canAccessPrismicContent } from '@modules/auth/utils';
import * as NEA from 'fp-ts/NonEmptyArray';
import { useRole } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';

interface PagesVideosProps {
  item: PagesPrismicSliceVideos;
  index: number;
}

const PagesVideos: FC<PagesVideosProps> = ({ item, index }) => {
  const { primary } = item;

  const role = useRole();

  const items = pipe(
    item.items,
    A.filter(item => canAccessPrismicContent(item.displayBlock, role)),
    NEA.fromArray,
  );

  return renderOptional(items, items => (
    <Styled.PagesVideosContainer>
      <Title title={prismicH.asText(primary.title)} />

      {items.length > 2 ? (
        <GalleryVideos items={items} index={index} />
      ) : (
        <Styled.PagesVideosItems length={items.length}>
          {items.map((item, i) => (
            <Styled.PagesVideosPlayer key={i}>
              <div dangerouslySetInnerHTML={{ __html: item.video.html ?? '' }} />
            </Styled.PagesVideosPlayer>
          ))}
        </Styled.PagesVideosItems>
      )}
    </Styled.PagesVideosContainer>
  ));
};

export default PagesVideos;
