import styled from 'styled-components';
import { renderRatio } from '@styles/utils/image';
import { primaryColor } from '@styles/utils';

export const PagesImagesContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 50px 20px;
  }
`;

export const PagesImagesGallery = styled.div`
  position: relative;
`;

export const PagesImagesGallerySlide = styled.li`
  cursor: pointer;

  > div > div {
    ${renderRatio(16 / 9)};
  }
`;

export const PagesImagesGalleryArrowsContainer = styled.div<{ $isShow: boolean }>`
  display: ${props => (props.$isShow ? 'block' : 'none')};

  @media screen and (max-width: 900px) {
    display: block;
  }
`;

export const GalleryArrow = styled.button`
  position: absolute;
  display: block;
  cursor: pointer;
  top: 50%;
  z-index: 10;
  background-color: ${primaryColor(500)};
  outline: none;
  border: none;

  &:hover {
    outline: solid 3px ${primaryColor(200)};
  }
`;

export const PagesImagesGalleryArrowLeft = styled(GalleryArrow)`
  padding: 15px 18px;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
`;

export const PagesImagesGalleryArrowRight = styled(GalleryArrow)`
  padding: 15px 18px;
  right: 0;
  transform: translateX(50%) translateY(-50%);
`;
