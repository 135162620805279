import { Locale } from '@shared/modules/translation/model';

export type SearchableDocumentContent =
  | string
  | SearchableDocumentContentRecord
  | SearchableDocumentContentArray
  | null;

export interface SearchableDocumentContentRecord extends Record<string, SearchableDocumentContent> {}
export interface SearchableDocumentContentArray extends Array<SearchableDocumentContent> {}

export interface SearchableDocument {
  objectID: string;
  id: string;
  type: 'home' | 'pages';
  role: SearchRole;
  uid?: string | null;
  lang: Locale;
  data: SearchableDocumentContent;
}

export enum SearchRole {
  Public = 'public',
  Retailer = 'retailer',
  EndUser = 'end-user',
}
