import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const TitleContainer = styled.p`
  position: relative;
  padding-bottom: 20px;
  ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};

  &:before {
    content: '';
    position: absolute;
    left: -54px;
    top: 20px;
    width: 30px;
    height: 4px;
    background-color: ${primaryColor(700)};

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
`;
