import React, { FC, useEffect, useState } from 'react';
import * as Styled from './PagesVideos.styles';
import Glide from '@glidejs/glide';
import { isFilled } from '@prismicio/helpers';
import { PagesPrismicSliceVideos } from '@modules/pages/model';
import Image from 'next/image';
import arrowIcon from '@assets/icons/arrow.svg';
import Modal from '@shared/components/modal/Modal';

interface GalleryVideosProps {
  items: PagesPrismicSliceVideos['items'];
  index: number;
}

const GalleryVideos: FC<GalleryVideosProps> = ({ items, index }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [indexVideo, setIndex] = useState<number | null>(null);

  useEffect(() => {
    const container = document.querySelector(`.gallery-videos-${index}`);

    if (container) {
      const slide = new Glide(`.gallery-videos-${index}`, {
        type: 'slider',
        startAt: 0,
        perView: 3,
        gap: 37,
        keyboard: false,
        rewind: false,
        breakpoints: {
          900: {
            perView: 2,
          },
          600: {
            perView: 1,
          },
        },
      });

      slide.mount();

      return () => {
        slide.destroy();
      };
    }
  }, [index]);

  const handleOpenVideo = (i: number) => () => {
    setOpen(true);
    setIndex(i);
  };

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
  };

  return (
    <Styled.PagesVideosGallery>
      <div className={`glide gallery-videos-${index}`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {items.map((it, i) => (
              <Styled.PagesVideosGallerySlide key={i} onClick={handleOpenVideo(i)}>
                <div>
                  {isFilled.embed(it.video) ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img src={it.video.thumbnail_url as any} alt="Video" />
                  ) : null}
                </div>

                <Styled.PagesVideosGallerySlideLabel>{it.videoTitle}</Styled.PagesVideosGallerySlideLabel>
              </Styled.PagesVideosGallerySlide>
            ))}
          </ul>
        </div>

        <div className="glide__arrows" data-glide-el="controls">
          <Styled.PagesVideosGalleryArrowLeft data-glide-dir="<">
            <Image src={arrowIcon.src} width={10} height={17} />
          </Styled.PagesVideosGalleryArrowLeft>

          <Styled.PagesVideosGalleryArrowRight data-glide-dir=">">
            <Image src={arrowIcon.src} width={10} height={17} />
          </Styled.PagesVideosGalleryArrowRight>
        </div>
      </div>

      {indexVideo != null ? (
        <Modal isOpen={isOpen} onRequestClose={handleClose} closeButton>
          <Styled.PagesVideosGalleryPlayer>
            <div dangerouslySetInnerHTML={{ __html: items[indexVideo].video.html ?? '' }} />
          </Styled.PagesVideosGalleryPlayer>
        </Modal>
      ) : null}
    </Styled.PagesVideosGallery>
  );
};

export default GalleryVideos;
