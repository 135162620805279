import { PrismicDisplayBlock, Role } from '@modules/auth/model';

export function canAccessPrismicContent(displayBlock: PrismicDisplayBlock | null, role: Role | null): boolean {
  switch (displayBlock) {
    case PrismicDisplayBlock.Retailer:
      return role === Role.Retailer;
    case PrismicDisplayBlock.EndUser:
      return role !== null;
    default:
      return true;
  }
}
