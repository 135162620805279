import { z } from 'zod';
import { emailZodSchema } from '@shared/schemas/email';
import { Address, addressZodSchema } from '@shared/schemas/address';
import { Translations } from '@shared/modules/translation/locales';
import { Language } from '@shared/modules/translation/model';

export const authenticateSchema = z.object({
  email: emailZodSchema.min(1),
  password: z.string().min(1),
});

export type AuthenticateParams = z.infer<typeof authenticateSchema>;

export const signUpUser = z
  .object({
    lastName: z.string().min(1),
    firstName: z.string().min(1),
    email: emailZodSchema,
    isRetailer: z.boolean(),
    companyName: z.string().nullish(),
    address: addressZodSchema,
    phoneNumber: z.string().min(1),
    plainPassword: z.string().min(1),
    confirmPassword: z.string().min(1),
  })
  .refine(val => !val.isRetailer || !!val.companyName, {
    path: ['companyName'],
  });

export const signUpSchema = z.object({
  equipmentSerialId: z.string().min(1),
  user: signUpUser,
  accept: z.boolean().refine(val => val),
});

export function getSignUpSchema(translations: Translations) {
  return signUpSchema.refine(val => val.user.plainPassword === val.user.confirmPassword, {
    path: ['user.confirmPassword'],
    message: translations.error.message.confirmPasswordError,
  });
}

export type SignUpFormBody = z.infer<typeof signUpSchema>;

export interface SignupParams extends SignUpFormBody {
  language: Language;
}

export interface Profile {
  email: string;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  address: Address;
  phoneNumber: string;
  role: Role;
  language: Language;
}

export enum Role {
  Retailer = 'retailer',
  EndUser = 'end-user',
}

export const accountSchema = z
  .object({
    email: emailZodSchema,
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    companyName: z.string().nullish(),
    address: addressZodSchema,
    phoneNumber: z.string().min(1),
    role: z.string().min(1),
  })
  .refine(val => val.role === Role.Retailer || !!val.companyName, {
    path: ['companyName'],
  });

export type AccountParams = Omit<Profile, 'role' | 'email' | 'language'>;

export interface EmailParams {
  email: string;
}

export enum PrismicDisplayBlock {
  Public = 'Public',
  Retailer = 'Revendeur',
  EndUser = 'Non revendeur',
}

export const forgotPasswordSchema = z.object({
  email: emailZodSchema,
});

export type ForgotPasswordParams = z.infer<typeof forgotPasswordSchema>;

export type ResetPasswordDetail = z.infer<typeof forgotPasswordSchema>;

export function getResetPasswordSchema(translations: Translations) {
  return z
    .object({
      password: z.string().min(1),
      confirmPassword: z.string().min(1),
    })
    .refine(val => val.password === val.confirmPassword, {
      path: ['confirmPassword'],
      message: translations.error.message.confirmPasswordError,
    });
}

export const resetPasswordSchema = z
  .object({
    password: z.string().min(1),
    confirmPassword: z.string().min(1),
  })
  .refine(val => val.password === val.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Les mots de passe ne correspondent pas',
  });

export type ResetPasswordParams = z.infer<typeof resetPasswordSchema>;

export namespace Authenticate {
  export const LimacaptSAVToken = z.string().brand<'LimacaptSAVToken'>();
  export type LimacaptSAVToken = z.infer<typeof LimacaptSAVToken>;
  export interface LimacaptLoginIn {
    token: LimacaptSAVToken;
  }
}
