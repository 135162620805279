import React, { FC } from 'react';
import * as Styled from './Title.styles';

interface TitleProps {
  title: string;
}

const Title: FC<TitleProps> = ({ title }) => <Styled.TitleContainer>{title}</Styled.TitleContainer>;

export default Title;
