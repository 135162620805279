import React, { ChangeEventHandler, FC, useMemo, useState } from 'react';
import * as Styled from './PagesFAQ.styles';
import * as prismicH from '@prismicio/helpers';
import { PagesPrismicSliceFAQ } from '@modules/pages/model';
import Title from '@shared/components/title/Title';
import { RichText } from '@core/prismic';
import SearchInput from '@styles/shared/form/SearchInput';

interface PagesFAQProps {
  item: PagesPrismicSliceFAQ;
  index: number;
}

const PagesFAQ: FC<PagesFAQProps> = ({ item, index }) => {
  const [isOpen, setOpen] = useState<number | null>(null);
  const [filter, setFilter] = useState<string>('');
  const { primary, items } = item;

  const handleFilter: ChangeEventHandler<HTMLInputElement> = event => setFilter(event.target.value);

  const handleDelete = () => setFilter('');

  const search = useMemo(
    () =>
      items.filter(
        it =>
          !filter ||
          prismicH.asText(it.title).search(new RegExp(filter, 'i')) !== -1 ||
          prismicH.asText(it.text).search(new RegExp(filter, 'i')) !== -1,
      ),
    [items, filter],
  );

  const handleClick = (i: number) => () => {
    setOpen(old => (old === i ? null : i));
    setTimeout(() =>
      document.getElementById(`faq-${i}-${index}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
    );
  };

  return (
    <Styled.PagesFAQWrapper>
      <Styled.PagesFAQContainer>
        <Title title={prismicH.asText(primary.title)} />

        <SearchInput
          label=""
          value={filter}
          placeholder="Rechercher un mot-clé dans la FAQ"
          onChange={handleFilter}
          isEmpty={filter.length === 0}
          onDelete={handleDelete}
        />

        <Styled.PagesFAQContent>
          {search.length > 0 ? (
            search.map((it, i) => (
              <Styled.PagesFAQItem key={i} id={`faq-${i}-${index}`}>
                <Styled.PagesFAQItemHeader onClick={handleClick(i)} $show={i === isOpen}>
                  {prismicH.asText(it.title)}

                  <Styled.PagesFAQItemArrow
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.179"
                    height="10.608"
                    viewBox="0 0 17.179 10.608"
                    $show={i === isOpen}>
                    <path
                      d="m12.885 23.785 6.557-6.571-6.557-6.571L14.9 8.625l8.589 8.589L14.9 25.8z"
                      transform="rotate(90 19.345 6.46)"
                    />
                  </Styled.PagesFAQItemArrow>
                </Styled.PagesFAQItemHeader>
                <Styled.PagesFAQItemContent $show={i === isOpen}>
                  <RichText content={it.text} />
                </Styled.PagesFAQItemContent>
              </Styled.PagesFAQItem>
            ))
          ) : (
            <p>Aucun résultat</p>
          )}
        </Styled.PagesFAQContent>
      </Styled.PagesFAQContainer>
    </Styled.PagesFAQWrapper>
  );
};

export default PagesFAQ;
