import { z } from 'zod';

export const addressZodSchema = z.object({
  country: z.string().min(1),
  city: z.string().min(1),
  postalCode: z.string().regex(/^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/, { message: 'Le code postal est incorrect' }),
  firstLine: z.string().min(1),
  secondLine: z.string().nullish(),
});

export type Address = z.infer<typeof addressZodSchema>;
