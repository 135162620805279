import React, { FC } from 'react';
import * as Styled from './PagesNavigation.styles';
import * as prismicH from '@prismicio/helpers';
import Title from '@shared/components/title/Title';
import { PagesPrismicSliceNavigation } from '@modules/pages/model';
import Navigation from '@shared/components/navigation/Navigation';

interface PagesNavigationProps {
  item: PagesPrismicSliceNavigation;
}

const PagesNavigation: FC<PagesNavigationProps> = ({ item }) => {
  const { primary, items } = item;
  return (
    <Styled.PagesNavigationWrapper>
      <Styled.PagesNavigationContainer>
        <Title title={prismicH.asText(primary.title)} />

        <Styled.PagesNavigationContent>
          {items.map((it, index) => (
            <Navigation title={it.title} description={it.description} link={it.link} key={index} />
          ))}
        </Styled.PagesNavigationContent>
      </Styled.PagesNavigationContainer>
    </Styled.PagesNavigationWrapper>
  );
};

export default PagesNavigation;
