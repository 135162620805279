import * as NEA from 'fp-ts/NonEmptyArray';
import { Diagnostic, DiagnosticsStepDetail, StepId } from '@modules/diagnostics/model';
import httpService from '@core/http/service';
import { NextPageContext } from 'next';
import { HttpTask } from '@core/http';

const URI = '/diagnostics';

export function getDiagnosticsByNumbers(
  numbers: NEA.NonEmptyArray<number>,
  ctx?: NextPageContext,
): HttpTask<NEA.NonEmptyArray<Diagnostic>> {
  return httpService.post(URI, numbers, ctx);
}

export function getDiagnosticsDetail(diagnosticNumber: number): HttpTask<Diagnostic> {
  return httpService.get(`${URI}/${diagnosticNumber}`);
}

export function getDiagnosticsStepDetail(stepId: StepId): HttpTask<DiagnosticsStepDetail> {
  return httpService.get(`diagnostic-step/${stepId}`);
}

export function trackingDiagnostics(diagnosticNumber: number): HttpTask {
  return httpService.post(`${URI}/${diagnosticNumber}/tracking`);
}
