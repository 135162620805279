import styled from 'styled-components';
import { complementaryColor, fontSize, fontWeight, mediaMax, primaryColor, tertiaryColor } from '@styles/utils';
import arrowIcon from '@assets/icons/diagnostics/arrow.svg';
import arrowHoverIcon from '@assets/icons/diagnostics/arrow-hover.svg';
import CustomLink from '@shared/components/link/CustomLink';
import Image from '@shared/modules/resources/components/Image';

export const PagesDiagnosticsContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 40px 16px;
  }
`;

export const PagesDiagnosticsDescription = styled.div`
  ${fontSize.standard};
  color: ${tertiaryColor(700)};
`;

export const PagesDiagnosticsContent = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (${mediaMax.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const PagesDiagnosticsItem = styled(CustomLink)`
  padding: 24px 20px;
  background: url(${arrowIcon.src}) no-repeat calc(100% - 26px) 37px ${complementaryColor('white')};
  outline: 2px solid ${primaryColor(200)};
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    background: url(${arrowHoverIcon.src}) no-repeat calc(100% - 26px) 37px ${complementaryColor('white')};
    outline: 3px solid #0b654c;
  }
`;

export const PagesDiagnosticsItemLabel = styled.p`
  padding-right: 25px;
  text-decoration: none;
  ${fontSize.large};
  font-weight: ${fontWeight.standard};
  color: ${tertiaryColor(700)};
`;

export const PagesDiagnosticsItemImage = styled(Image)`
  margin-top: 15px;
`;
