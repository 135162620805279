import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import Image from '@core/prismic/components/Image';
import { RichText } from '@core/prismic';

export const PagesTitleContainer = styled.div`
  position: relative;
  padding: 104px 0 56px 0;
  background-color: ${primaryColor(500)};
  overflow: hidden;

  @media screen and (max-width: 1230px) {
    padding: 125px 16px 40px 16px;
  }
`;

export const PagesTitleBackground = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
`;

export const PagesTitleMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: ${tertiaryColor(900)};
`;

export const PagesTitleContent = styled.div`
  padding: 20px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 5px ${tertiaryColor(100)};
  width: max-content;

  @media screen and (max-width: 1230px) {
    max-width: 100%;
    line-height: 1.2;
  }
`;

export const PagesTitleLogo = styled(Image)`
  padding-right: 24px;
  display: inline-flex;
`;

export const PagesTitle = styled(RichText)`
  h1 {
    ${fontSize.h1};
    font-weight: ${fontWeight.semiBold};
    color: ${tertiaryColor(100)};
    text-align: left;
  }
`;
