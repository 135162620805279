import styled from 'styled-components';
import { primaryColor } from '@styles/utils';

export const PagesNavigationWrapper = styled.div`
  margin-top: 64px;
  background-color: ${primaryColor(100)};
`;

export const PagesNavigationContainer = styled.div`
  margin: 0 auto;
  padding: 64px 0;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 40px 16px;
  }
`;

export const PagesNavigationContent = styled.div`
  padding-top: 18px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 36px;
  row-gap: 20px;

  @media screen and (max-width: 1230px) {
    grid-template-columns: 1fr;
  }
`;
