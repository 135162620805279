import React, { FC } from 'react';
import * as Styled from './PagesRichText.styles';
import { PagesPrismicSliceRichText } from '@modules/pages/model';

interface PagesRichTextProps {
  item: PagesPrismicSliceRichText;
}

const PagesRichText: FC<PagesRichTextProps> = ({ item }) => {
  return (
    <Styled.PagesRichTextContainer>
      <Styled.PagesRichTextDescription content={item.primary.text} />
    </Styled.PagesRichTextContainer>
  );
};

export default PagesRichText;
