import * as EI from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import isEqual from 'lodash.isequal';
import { useEffect, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { HttpError, HttpResult, HttpTask } from '.';

function useCurrentArgs<P extends unknown[]>(...args: P) {
  const [currentArgs, setCurrentArgs] = useState(args);

  useEffect(() => {
    setCurrentArgs(old => (!isEqual(old, args) ? args : old));
  }, [args]);

  return currentArgs;
}

export function useSendTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  task: (...args: [...P1, ...P2]) => HttpTask<R, E>,
  ...args: P1
): [boolean, (...args: P2) => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<HttpError<E>>>(O.none);
  const [success, setSuccess] = useState<boolean>(false);

  const debouncedSetLoading = useDebouncedCallback(setLoading, 100);

  const currentArgs = useCurrentArgs(...args);

  const sendRequest = useCallback(
    (...args2: P2) => {
      debouncedSetLoading(true);
      setError(O.none);
      setSuccess(false);

      return pipe(
        task(...[...currentArgs, ...args2]),
        T.chainIOK(res => () => {
          debouncedSetLoading(false);
          setSuccess(EI.isRight(res));
          setError(O.fromEither(EI.swap(res)));

          return res;
        }),
      )();
    },
    [task, currentArgs, debouncedSetLoading],
  );

  return [loading, sendRequest, error, success];
}
