import React, { FC } from 'react';
import Image from 'next/image';
import * as Styled from './PagesDocuments.styles';
import * as prismicH from '@prismicio/helpers';
import { PagesPrismicSliceDocuments } from '@modules/pages/model';
import Title from '@shared/components/title/Title';
import downloadIcon from '@assets/icons/download.svg';
import { isFilled } from '@prismicio/helpers';
import { useRole } from '@modules/auth/context';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { canAccessPrismicContent } from '@modules/auth/utils';
import { renderOptional } from '@shared/utils/render';

interface PagesDocumentsProps {
  item: PagesPrismicSliceDocuments;
}

const PagesDocuments: FC<PagesDocumentsProps> = ({ item }) => {
  const { primary } = item;

  const role = useRole();

  const items = pipe(
    item.items,
    A.filter(item => canAccessPrismicContent(item.displayBlock, role)),
    NEA.fromArray,
  );

  return renderOptional(items, items => (
    <Styled.PagesDocumentsContainer>
      <Title title={prismicH.asText(primary.title)} />

      <Styled.PagesDocumentsContent>
        {items.map((it, i) =>
          it.displayBlock ? (
            <Styled.PagesDocumentsItem key={i}>
              <Styled.PagesDocumentsText>
                <Styled.PagesDocumentsTitle>{it.title}</Styled.PagesDocumentsTitle>

                <Styled.PagesDocumentsDescription>{it.description}</Styled.PagesDocumentsDescription>
              </Styled.PagesDocumentsText>

              {isFilled.linkToMedia(it.document) ? (
                <Styled.PagesDocumentsImage>
                  <a href={it.document.url} target="_blank" rel="noreferrer" download>
                    <Image src={downloadIcon} width={19} height={19} alt="Download" />
                  </a>
                </Styled.PagesDocumentsImage>
              ) : null}
            </Styled.PagesDocumentsItem>
          ) : null,
        )}
      </Styled.PagesDocumentsContent>
    </Styled.PagesDocumentsContainer>
  ));
};

export default PagesDocuments;
