import styled from 'styled-components';
import { renderRatio } from '@styles/utils/image';
import { fontSize, fontWeight, mediaMax, primaryColor } from '@styles/utils';

export const PagesVideosContainer = styled.div`
  margin: 0 auto;
  padding-top: 64px;
  width: 100%;
  max-width: 1190px;

  @media screen and (max-width: 1230px) {
    padding: 40px 16px;
  }
`;

export const PagesVideosPlayer = styled.div`
  margin: 0 auto;
  max-width: 887px;
  width: 100%;

  > div {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

export const PagesVideosGallery = styled.div`
  position: relative;
`;

export const PagesVideosGallerySlide = styled.li`
  cursor: pointer;

  > div {
    ${renderRatio(16 / 9)};
  }
`;

export const PagesVideosGallerySlideLabel = styled.p`
  padding-top: 8px;
  ${fontSize.label};
  font-weight: ${fontWeight.standard};
  text-align: center;
`;

export const GalleryArrow = styled.button`
  position: absolute;
  display: block;
  cursor: pointer;
  top: 50%;
  z-index: 10;
  background-color: ${primaryColor(500)};
  outline: none;
  border: none;

  &:hover {
    outline: solid 3px ${primaryColor(200)};
  }
`;

export const PagesVideosGalleryArrowLeft = styled(GalleryArrow)`
  padding: 15px 18px;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
`;

export const PagesVideosGalleryArrowRight = styled(GalleryArrow)`
  padding: 15px 18px;
  right: 0;
  transform: translateX(50%) translateY(-50%);
`;

export const PagesVideosGalleryPlayer = styled.div`
  margin: 0 auto;
  max-width: 887px;
  width: 100%;

  > div {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

export const PagesVideosItems = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.length}, 1fr);
  grid-column-gap: 37px;

  @media screen and (${mediaMax.tablet}) {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
`;

export const PagesVideosItem = styled.div`
  cursor: pointer;

  > div {
    ${renderRatio(16 / 9)};
  }
`;
