import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../utils';
import { getSharedFormElementStyle, useFormElementEvent } from './shared';
import searchIcon from '@assets/icons/search.svg';
import closeIcon from '@assets/icons/dark-close.svg';

const SearchInputContainer = styled.div<{ active: boolean; focused: boolean; inverted?: boolean; error?: boolean }>`
  ${props => getSharedFormElementStyle(props.active, props.focused, props.inverted, props.error)};
  margin-bottom: 18px;
  padding: 0 20px 0 25px;
  background: url(${searchIcon.src}) no-repeat left center;
  border-bottom: solid 1px rgba(112, 112, 112, 0.4);

  input {
    position: relative;
    height: 35px;
    width: 100%;
    border: none;
    background: none !important;
    z-index: 1;
    font: inherit;
    ${fontSize.standard};
    color: #000000;

    &:after {
      display: block;
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background: #000000;
    }
  }

  div {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }

  span {
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }
`;

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  inverted?: boolean;
  error?: boolean;
  isEmpty: boolean;
  onDelete: () => void;
  className?: string;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { label, inverted, error, isEmpty, onDelete, className, id, type, value, onChange, onFocus, onBlur, ...props },
    ref,
  ) => {
    const { active, focused, handleBlur, handleFocus, handleChange } = useFormElementEvent(
      value,
      onFocus,
      onBlur,
      onChange,
    );

    return (
      <SearchInputContainer active={active} focused={focused} inverted={inverted} error={error} className={className}>
        <label htmlFor={id}>{label}</label>
        <input
          ref={ref}
          type={type ?? 'text'}
          id={id}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...props}
        />
        {!isEmpty ? (
          <div onClick={onDelete}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={closeIcon.src} width={13} height={13} alt="Close" />
          </div>
        ) : null}
        <span />
      </SearchInputContainer>
    );
  },
);

export default SearchInput;
