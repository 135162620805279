import React, { FC, useEffect, useState } from 'react';
import NextImage from 'next/image';
import * as Styled from './PagesImages.styles';
import * as O from 'fp-ts/Option';
import * as prismicH from '@prismicio/helpers';
import { PagesPrismicSliceImages } from '@modules/pages/model';
import Title from '@shared/components/title/Title';
import Image from '@core/prismic/components/Image';
import { isFilled } from '@prismicio/helpers';
import Glide from '@glidejs/glide';
import arrowIcon from '@assets/icons/arrow.svg';
import { renderOptional } from '@shared/utils/render';
import ImagesDetail from '@shared/components/images/ImagesDetail';

function getImageSizes(length: number, size: string, nbItems: number) {
  if (length < nbItems) return `calc(${size}, ${length})`;
  else return `calc(${size}, ${nbItems})`;
}

interface PagesImagesProps {
  item: PagesPrismicSliceImages;
  index: number;
}

const PagesImages: FC<PagesImagesProps> = ({ item, index }) => {
  const { primary, items } = item;

  const [galleryIndex, setGalleryIndex] = useState<O.Option<number>>(O.none);

  useEffect(() => {
    const container = document.querySelector(`.gallery-images-${index}`);

    if (container) {
      const slide = new Glide(`.gallery-images-${index}`, {
        type: 'slider',
        startAt: 0,
        perView: items.length > 3 ? 3 : items.length,
        gap: 37,
        keyboard: false,
        rewind: false,
        swipeThreshold: items.length > 3 ? 80 : 1000,
        dragThreshold: items.length > 3 ? 80 : 1000,
        touchAngle: items.length > 3 ? 45 : 0,
        breakpoints: {
          900: {
            perView: 2,
            swipeThreshold: 80,
            dragThreshold: 80,
            touchAngle: 45,
          },
          600: {
            perView: 1,
          },
        },
      });

      slide.mount();

      return () => {
        slide.destroy();
      };
    }
  }, [index, items.length]);

  const handleOpenGallery = (i: number) => () => setGalleryIndex(O.some(i));

  const handleCloseGallery = () => setGalleryIndex(O.none);

  const handleMovePrev = (i: number) => () => setGalleryIndex(O.some((i + items.length - 1) % items.length));

  const handleMoveNext = (i: number) => () => setGalleryIndex(O.some((i + 1) % items.length));

  return (
    <Styled.PagesImagesContainer>
      <Title title={prismicH.asText(primary.title)} />

      <Styled.PagesImagesGallery>
        <div className={`glide gallery-images-${index}`}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {items.map((it, i) => (
                <Styled.PagesImagesGallerySlide key={i} onClick={handleOpenGallery(i)}>
                  <div>
                    {isFilled.image(it.image) ? (
                      <Image
                        sizes={{
                          [`(max-width: 600px)`]: '100vw',
                          [`(max-width: 900px)`]: getImageSizes(items.length, '100vw', 2),
                          default: getImageSizes(items.length, '1200px', 3),
                        }}
                        image={it.image}
                        imgixParams={{ ar: '16:9', fit: 'crop', crop: 'faces, edges' }}
                        lazy
                      />
                    ) : null}
                  </div>
                </Styled.PagesImagesGallerySlide>
              ))}
            </ul>
          </div>

          <Styled.PagesImagesGalleryArrowsContainer
            className="glide__arrows"
            data-glide-el="controls"
            $isShow={items.length > 3}>
            <Styled.PagesImagesGalleryArrowLeft data-glide-dir="<">
              <NextImage src={arrowIcon.src} width={10} height={17} />
            </Styled.PagesImagesGalleryArrowLeft>

            <Styled.PagesImagesGalleryArrowRight data-glide-dir=">">
              <NextImage src={arrowIcon.src} width={10} height={17} />
            </Styled.PagesImagesGalleryArrowRight>
          </Styled.PagesImagesGalleryArrowsContainer>
        </div>
      </Styled.PagesImagesGallery>

      {renderOptional(galleryIndex, index =>
        isFilled.image(items[index].image) ? (
          <ImagesDetail
            images={items.map(it => it.image)}
            onClose={handleCloseGallery}
            index={index}
            onMovePrevRequest={handleMovePrev(index)}
            onMoveNextRequest={handleMoveNext(index)}
          />
        ) : null,
      )}
    </Styled.PagesImagesContainer>
  );
};

export default PagesImages;
