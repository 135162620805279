import React, { FC } from 'react';
import * as Styled from './Pages.styles';
import * as prismicH from '@prismicio/helpers';
import { isFilled } from '@prismicio/helpers';
import { getSeoFromPrismicDocument, PrismicDocument } from '@core/prismic';
import { PagesPrismicContent, PagesPrismicContentSlices } from '@modules/pages/model';
import Seo from '@shared/components/seo/Seo';
import PagesRichText from '@modules/pages/components/rich-text/PagesRichText';
import PagesImages from '@modules/pages/components/images/PagesImages';
import PagesVideos from '@modules/pages/components/videos/PagesVideos';
import PagesDocuments from '@modules/pages/components/documents/PagesDocuments';
import PagesNavigation from '@modules/pages/components/navigation/PagesNavigation';
import PagesFAQ from '@modules/pages/components/faq/PagesFAQ';
import { Diagnostic } from '@modules/diagnostics/model';
import PagesDiagnostics from '@modules/pages/components/diagnostics/PagesDiagnostics';

interface PagesProps {
  document: PrismicDocument<PagesPrismicContent>;
  diagnostics: Array<Diagnostic>;
}

const Pages: FC<PagesProps> = ({ document, diagnostics }) => {
  const render = (item: PagesPrismicContentSlices, i: number) => {
    switch (item.slice_type) {
      case 'rich_text':
        return <PagesRichText item={item} key={i} />;
      case 'documents':
        return <PagesDocuments item={item} key={i} />;
      case 'photos':
        return <PagesImages item={item} index={i} key={i} />;
      case 'videos':
        return <PagesVideos item={item} index={i} key={i} />;
      case 'navigation':
        return <PagesNavigation item={item} key={i} />;
      case 'faq':
        return <PagesFAQ item={item} index={i} key={i} />;
      case 'diagnostics':
        return <PagesDiagnostics item={item} diagnostics={diagnostics} key={i} />;
      default:
        return null;
    }
  };

  const title = prismicH.asText(document.data.title);

  return (
    <>
      <Seo
        {...getSeoFromPrismicDocument(document)}
        noIndex={false}
        canonical={{ to: 'pages', params: { uid: document.uid } }}
      />

      <Styled.PagesTitleContainer>
        {isFilled.image(document.data.backgroundImage) ? (
          <>
            <Styled.PagesTitleBackground image={document.data.backgroundImage} sizes={{ default: '100vw' }} />

            <Styled.PagesTitleMask />
          </>
        ) : null}

        {title.length > 0 ? (
          <Styled.PagesTitleContent>
            {isFilled.image(document.data.logo) ? (
              <Styled.PagesTitleLogo image={document.data.logo} width={145} />
            ) : null}

            <Styled.PagesTitle content={document.data.title} />
          </Styled.PagesTitleContent>
        ) : null}
      </Styled.PagesTitleContainer>

      {document.data.slices.map((item, i) => render(item, i))}
    </>
  );
};

export default Pages;
