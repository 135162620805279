import styled from 'styled-components';

import closeIcon from '@assets/icons/close.svg';

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 43px;
  height: 43px;
  border: none;
  background: url(${closeIcon.src}) no-repeat center center;
  outline: none;
  cursor: pointer;
`;
